import { twMerge } from 'tailwind-merge';

import Text from '../../Text/Text';

export const ErrorMessage = ({
  error,
  className,
}: {
  error: string;
  className?: string;
}) => (
  <Text size="xs" as="span" className={twMerge('text-red-800', className)}>
    {error}
  </Text>
);

export default ErrorMessage;
